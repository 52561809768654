@font-face {
    font-family: "Audiowide";
    src: url("../fonts/Audiowide.ttf");
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter.ttf");
}

body {
    font-family: Inter;
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-card-title {
    font-family: Audiowide;
    font-weight: 100
}

* {
    /* transform: scale(0.80); */
    /* font-size: 11px; */
}

/* 
td,th {
    padding: 0px 20px !important
} */

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

.rotate-animation {
    animation: rotate infinite 2s linear;
}

.table-center * {
    text-align: center !important;
}

/* .MuiCard-root, .MuiCard-root *{
    max-height:80vh;
    overflow-y: scroll;
} */