.file-container {}

.file-preview {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    display: inline-block;
    width: 120px;
    margin-right: 10px;
    text-align: center;
}

.image-preview {
    max-width: 100px;
    max-height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.file-icon {
    width: 60px;
    height: 60px;
    border: 2px solid #4ea8c8;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #4ea8c8;
    margin-bottom: 20px;
}

.file-name-input {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
    transition: border-color 0.3s;
}

.file-name-input:focus {
    outline: none;
    border-color: #4ea8c8;
}